// export const paymentDetails = {
//     gpay:"ombk.aada39687z0l4c74p1v@mbk",
//     paytm:"ombk.aada39687z0l4c74p1v@mbk",
//     phonepe:"ombk.aada39687z0l4c74p1v@mbk",
//     upi:"ombk.aada39687z0l4c74p1v@mbk"
// }
//me
// export const paymentDetails = {
//     gpay:"drghkzyu6uuj@idbi",
//     paytm:"drghkzyu6uuj@idbi",
//     phonepe:"drghkzyu6uuj@idbi",
//     upi:"drghkzyu6uuj@idbi"
// }

// export const paymentDetails = {
//     gpay:"74ix6m8my3xv@idbi",
//     paytm:"74ix6m8my3xv@idbi",
//     phonepe:"74ix6m8my3xv@idbi",
//     upi:"74ix6m8my3xv@idbi"
// }

// export const paymentDetails = {
//     gpay:"ombk.aaex34657i2z9uley96@mbk",
//     paytm:"ombk.aaex34657i2z9uley96@mbk",
//     phonepe:"ccpay.6528680495134008@icici",
//     upi:"ccpay.6528680495134008@icici"
// }

export const paymentDetails = {
    gpay:"ombk.AAEH95965fll222kkem@mbk",
    paytm:"ombk.AAEH95965fll222kkem@mbk",
    phonepe:"ombk.AAEH95965fll222kkem@mbk",
    upi:"ombk.AAEH95965fll222kkem@mbk"
}